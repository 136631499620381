.project-page {
    width: 100%;
    top: 70px;
    position: relative;
    margin-bottom: 5rem;

    .app-header {
        align-items: center;
        justify-content: center;

        h1 {
            text-align: center;
        }
    }
    .app-body {
        display: flex;
        
        @include mobile {
            flex-direction: column;
        }
        .left {
            width: 60%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include mobile {
                width: 100%;
            }

            img{
                margin: 15px 0;
                border-radius: 20px;
                border: 2px solid $secondaryColor;
                width: 85%;
            }
        } 
        .right {
            width: 40%;
            padding: 30px;
            align-items: center;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            // position: relative;

            .project-links {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 1rem;

                span {
                    a {
                        font-size: 1.5rem;
                        text-decoration: none;
                        color: $secondaryColor;
                    }
                }
            }

            @include mobile {
                width: 100%;
                padding-top: 0;
            }
            .right-img-wrapper {
                padding-bottom: 5px;
            }
            p {
                font-size: 24px;
                margin: 60px 0;

                @include mobile {
                    margin: 0 0 30px 0;
                    font-size: 20px;
                }
            }
            h3 {
                font-size: 28px;

                @include mobile {
                    font-size: 20px;
                    text-align: center;
                }
            }
            img {
                // margin: 60px 0 15px;
                border-radius: 20px;
                // position: absolute;
                // bottom: 25px;
                width: 90%;

                @include mobile {
                    display: none;
                }
                
            }
        }
    }
}