.contact {
    background-color: white;
    display: flex;
    height: 100vh !important;

    @include mobile {
        flex-direction: column;
    }

    .left {
        flex: 1;
        overflow: hidden;

        img {
            height: 100%;
        }
    }
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            font-size: 30px;
        }
        form {
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            input {
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile {
                    width: 200px;
                    height: 20px;
                }
            }

            textarea {
                width: 300px;
                height: 200px;
                font-size: 14px;

                @include mobile {
                    width: 200px;
                    height: 100px;
                }
            }

            button {
                width: 150px;
                height: 30px;
                color: white;
                background-color: $secondaryColor;
                border: none;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer;
                transition: all 0.5s ease-out;


                &:hover {
                    transform: scale(1.05);
                }
            }
        }
        .links {
            display: flex;
            justify-content: space-around;
            width: 100%;
            margin-top: 20px;

            span {

                a {
                    text-decoration: none;
                }
                @include mobile {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}