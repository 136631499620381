.intro {
    background-color: white;
    display: flex;
    justify-content: flex-start;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    .left {
        flex: 0.5;
        overflow: hidden;

        .img-container {
            // width: 700px;
            width: 80%;
            // height: 100%;
            max-height: 700px;
            height: auto;
            background-color: $secondaryColor;
            border-radius: 50%;
            overflow: inherit;

            @include mobile {
                position: relative;
                width: 100%;
                height: 95%;
                justify-content: center;
            }

            img {
                height: 700px;

                @include mobile {
                    transform: translateX(50px);
                    height: 400px;
                }
            }
        }
    }

    .right {
        flex: 0.5;
        overflow: hidden;
        position: relative;

        .intro-wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile {
                padding-left: 0;
                align-items: center;
            }
        }

        h1 {
            font-size: 60px;
            margin: 10px 0;

            @include mobile {
                font-size: 40px;
            }
        }
        h2 {
            font-size: 35px;
            margin-bottom: 0;
        }
        h3 {
            font-size: 30px;
            margin-bottom: 0;

            @include mobile {
                font-size: 20px;
            }

            span {
                font-size: inherit;
                color: $secondaryColor;
                padding-left: 6px;
            }

            .ityped-cursor {
                padding-left: 0;
                animation: blink 1s infinite;
            }

            @keyframes blink {
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        }
        
        a {
            position: absolute;
            bottom: 10px;
            left: 30%;

            img {
                width: 50px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }
        }

        
    }
}