html {
    box-sizing: border-box;
}
  *, *:before, *:after {
      box-sizing: inherit;
  }

* {
    overflow-x: hidden;
}

body {
    margin: 0;
    // min-height: 100%;
}

h1, h2, h3, p {
    margin-top: 0;
}

img {
    max-width: 100%;
    height: auto;
}