.portfolioList {
    font-size: 24px;
    margin: 0 25px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile {
        margin-right: 20px;
    }

    &.active {
        background-color: $mainColor;
        color: white;
    }
}