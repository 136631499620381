.about {
    display: flex;
    padding: 0 50px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include mobile {
        justify-content: flex-start;
    }

    .information {
        height: 40%;

        @include mobile {
            height: auto;
        }

        p {
            font-size: 1em;
            line-height: 1.6em;
        }
    }
    .img-container {
        height: 40%;
        overflow: hidden;

        @include mobile {
            display: none;
        }

        img {
            border-radius: 10px;
        }
    }

    



    img {
        height: 100%;
    }
}